const accordionToggle = {
	init: accordion => {
		accordion.question.addEventListener('click', () => accordionToggle.handleAccordionQuestionClicked(accordion));
	},

	closeAccordion: accordion => {
		const { answer, question, item } = accordion;
		question.classList.remove('active');
		answer.setAttribute('inert', true);
		accordionToggle.setAccordionHeight(answer, 0);

		//TIMEOUT FOR ANIMATION ON CLOSE (STICKY PLASTER DUE TO MOD-ROW)
		setTimeout(() => {
			item.classList.remove('open');
		}, 300);
	},

	openAccordion: accordion => {
		const { answer, question, item } = accordion;

		question.classList.add('active');
		item.classList.add('open');
		accordionToggle.setAccordionHeight(answer, answer.scrollHeight);
		answer.removeAttribute('inert');
	},

	setAccordionHeight: (answer, height) => {
		//INLINE STYLE NEEDED AS USES DYNAMIC HEIGHT
		answer.style.maxHeight = `${height}px`;
	},

	handleAccordionQuestionClicked: accordion => {
		const accordionExpanded = accordion.question.classList.contains('active');
		accordionExpanded ? accordionToggle.closeAccordion(accordion) : accordionToggle.openAccordion(accordion);

		// SCROLL ACCORDION INTO VIEW
		accordionToggle.handleScrollIntoView(accordion.item);
	},

	handleScrollIntoView: accordionItem => {
		const offset = 81;
		const accordionItemScrollTop = accordionItem.getBoundingClientRect().top + window.scrollY - offset;
		window.scrollTo({ top: accordionItemScrollTop, behavior: 'smooth' });
	},
};

const showMoreFunctionality = {
	init: accordionGroup => {
		const accordions = accordionGroup.querySelectorAll('.mod-accordion__item'),
			dataItemsLoaded = accordionGroup.getAttribute('data-items-loaded'),
			toggleButton = accordionGroup.querySelector('.mod-accordion__show-more'),
			totalAccordions = accordions.length;

		let currentAccordionsDisplayed = parseInt(dataItemsLoaded);
		if (totalAccordions > currentAccordionsDisplayed) {
			toggleButton.classList.remove('display-none');
			showMoreFunctionality.toggleAccordionVisibility(dataItemsLoaded, accordions);
		} else {
			showMoreFunctionality.hideToggleButton(toggleButton);
		}

		toggleButton.addEventListener('click', e => {
			e.preventDefault();
			currentAccordionsDisplayed += 2;
			//IF ALL ACCORDIONS ARE SHOWING, HIDE THE TOGGLE BUTTON
			if (currentAccordionsDisplayed >= totalAccordions) showMoreFunctionality.hideToggleButton(toggleButton);
			showMoreFunctionality.toggleAccordionVisibility(currentAccordionsDisplayed, accordions);
		});
	},

	hideToggleButton: toggleButton => {
		if (toggleButton) toggleButton.classList.add('display-none');
	},

	toggleAccordionVisibility: (dataItemsShowing, accordions) => {
		accordions.forEach((accordion, index) => {
			index >= dataItemsShowing
				? accordion.classList.add('display-none')
				: accordion.classList.remove('display-none');
		});
	},
};

const windowResizeHandler = {
	resizing: false,
	closedAccordions: [],

	debounce: (func, delay) => {
		//DEBOUNCE FUNCTION FOR RESIZE
		let timeoutId;
		return function (...args) {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				func(...args);
			}, delay);
		};
	},

	//PLACEHOLDER
	debounceResizeHandler: null,
};

windowResizeHandler.debounceResizeHandler = windowResizeHandler.debounce(accordions => {
	accordions.forEach(accordion => {
		const accordionExpanded = accordion.question.classList.contains('active');

		if (accordionExpanded) {
			accordionToggle.setAccordionHeight(accordion.answer, accordion.answer.scrollHeight);
		}
	});
}, 200);

document.addEventListener('DOMContentLoaded', function () {
	const accordionGroups = document.querySelectorAll('.mod-accordion--show-more');
	const accordions = document.querySelectorAll('.mod-accordion__item');

	if (!accordions) return;

	const accordionObjectArray = [];
	accordions.forEach(accordionItem => {
		const accordionObject = {
			item: accordionItem,
			question: accordionItem.querySelector('.mod-accordion__question'),
			answer: accordionItem.querySelector('.mod-row'),
		};

		accordionObjectArray.push(accordionObject);
		accordionToggle.init(accordionObject);
	});

	//WINDOW RESIZE HANDLER
	window.addEventListener('resize', () => {
		windowResizeHandler.debounceResizeHandler(accordionObjectArray);
	});

	if (!accordionGroups) return;
	accordionGroups.forEach(accordionGroup => {
		showMoreFunctionality.init(accordionGroup);
	});
});
